






















































import Vue from 'vue'
import { Prop, Component, Mixins } from 'vue-property-decorator'
import { fetchPendingPayments, removePendingPayment } from '#/api/co-producer'
import { ModalStore, IModalParams, ModalType } from '~/store/modal'
import { UserStore } from '#/store/user'
import { updateProducer } from '#/api/profile'

@Component
export default class CoProducersPage extends Vue {
	confirmationPopup: boolean = false
	producerEmailToDelete: string = null
	producerEmailToAdd: string = ''
	pendingPayments: any[] = []

	@UserStore.State('authenticatedUser') authenticatedUser: IUserProducer

	get coproducerEmails(): IUserProducer['coproducers'] {
		return this.authenticatedUser ? this.authenticatedUser.coproducers : []
	}

	get coproducersWithPendingPayments() {
		if (this.coproducerEmails.length === 0) return null
		// if there are no pending payments, create new object with email = email and amount = 0
		if (this.pendingPayments.length === 0) return this.coproducerEmails.map(item => ({ coproducer: item, amount: 0 }))
		// otherwise, for each 'coproducer', find their pending payments
		return this.coproducerEmails.map(email => {
			const filteredPendingPayments = this.pendingPayments.filter(i => i.coproducer === email)
			// if there are more then 1, create a new object with their email and the sum of the amounts due
			if (filteredPendingPayments.length > 1) {
				return filteredPendingPayments.reduce((a, b) => ({ coproducer: a.coproducer, amount: a.amount + b.amount }))
			} else if (filteredPendingPayments.length === 1) {
				// if we only have one item, return
				return filteredPendingPayments[0]
			}
			// otherwise return the filtered array
			return { coproducer: email, amount: 0 }
		})
	}

	get thereArePendingPayments() {
		return this.coproducersWithPendingPayments ? this.coproducersWithPendingPayments.some(item => item.amount > 0) : false
	}

	@ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: (params: IModalParams) => void

	async fetchPendingPayments() {
		this.pendingPayments = await fetchPendingPayments()
	}

	addOrRemoveEmail(email: string) {
		const emailToUpdateIndex = this.coproducerEmails.findIndex(item => item === email)
		if (emailToUpdateIndex > -1) this.coproducerEmails.splice(emailToUpdateIndex, 1)
		else this.coproducerEmails.push(email)
	}

	async updateCoProducers(email: string) {
		if (!email.trim()) return
		updateProducer({ id: this.authenticatedUser.id, coproducers: this.coproducerEmails })
		this.addOrRemoveEmail(email)
		// reset data
		this.producerEmailToAdd = ''
		this.confirmationPopup = false
	}

	payCoproducer(email: string) {
		// if we don't pass the email reset everything, otherwise reset the payments for the coproducer specified in the email
		this.pendingPayments
			.filter(item => !email || item.coproducer === email)
			.map(async coProducer => {
				try {
					await removePendingPayment(coProducer.id)
					coProducer.amount = 0 // remove locally
					this.SET_ACTIVE_MODAL({ modal: ModalType.Success })
				} catch (err) {
					this.SET_ACTIVE_MODAL({ modal: ModalType.Error })
				}
			})
	}

	openConfirmationPopup(producerEmail: string) {
		this.confirmationPopup = true
		this.producerEmailToDelete = producerEmail
	}

	created() {
		this.fetchPendingPayments()
	}
}
