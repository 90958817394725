import { get, update, remove, post } from '~/services/http-requests'

const _BS_COPRODUCERS_OF_BEAT = 'coproducers_of_beat'
const _BS_PENDING_PAYMENTS = 'pending_coproducers_payments'

/*
 **** GET ****
 */
function fetchPendingPayments() {
    return get({ baseProperty: _BS_PENDING_PAYMENTS })
}

function fetchCoproducerDataOfBeat(beatId: IBeatProducer['id']): Promise<ICoProducer[]> {
    return get({ baseProperty: _BS_PENDING_PAYMENTS, fullUrl: `${_BS_COPRODUCERS_OF_BEAT}.json?beat=${beatId}` })
}

/*
 **** POST ****
 */
function createCoproducerOnBeat(coProducer: Partial<ICoProducer>): Promise<ICoProducer> {
    return post({ baseProperty: _BS_COPRODUCERS_OF_BEAT, dispatchParams: coProducer })
}

/*
 **** DELETE ****
 */
function removePendingPayment(coproducerId: ICoProducer['id']) {
    return remove({ baseProperty: _BS_PENDING_PAYMENTS, fullUrl: `${_BS_PENDING_PAYMENTS}/${coproducerId}/` })
}

function removeCoproducerOfBeat(coproducerId: ICoProducer['id']) {
    return remove({ baseProperty: _BS_PENDING_PAYMENTS, fullUrl: `${_BS_PENDING_PAYMENTS}/${coproducerId}/` })
}

export { fetchPendingPayments, fetchCoproducerDataOfBeat, createCoproducerOnBeat, removePendingPayment, removeCoproducerOfBeat }
